import React from "react";
import { graphql } from "gatsby";
import Img from "react-cloudinary-lazy-image";
import ServiceLayout from "../../components/ServiceLayout";
import ServiceTitle from "../../components/ServiceTitle";
import ServiceCard from "../../components/ServiceCard";
import { OfferingTextContent, SectionContainer } from '../../components/offerings';

import "../../styles.css";

export default props => {
  const markdownNodes = props.data.allMarkdownRemark.nodes;
  const websiteImageNodes = props.data.allCloudinaryMedia.nodes;

  const faqSections = [{
    header: "Own your content",
    subtitle: "Your website is built with quick and easy updates in mind.",
    detail: "Need to make a quick change to an image on your website? Easy. There's no additional charge to do this and you can do it as often as you like. We'll show you how."
  }, {
    header: "Modern standards, minimal maintenance",
    subtitle: "We make sure to meet modern web security and speed standards.",
    detail: "While the look and feel of a website is important, so is making sure the website is up and running, secure and fast. We make sure all websites built by us continually meet these standards while you get to spend more time with your customers regardless of the size or complexity of your website."
  }];

  const simpleWebsite = (
    <div className="px-8 text-left sm:text-center pb-10 max-w-3xl mx-auto">
      <OfferingTextContent
        header="Built to your needs"
        subtitle="When you work with us, together we'll create something that meets your needs, large or small."
        detail="Need to make updates to your existing business website? We can help!"
      />
    </div>
  );

  let titleComponent = (
    <ServiceTitle
      title='Seamless Website Design'
      subtitle="From minor updates to major rebuilds, we work with you to bring your digital dreams to the customer's computer screen. Take a look below to see some of our recent work!"
    >
    </ServiceTitle>
  );
  const content = markdownNodes.map((node, index) => {
    const { title, description, public_id, url } = node.frontmatter;
    const matchingImageNode = websiteImageNodes.find(node => node.public_id === public_id);

    const imgComponent = (
      <Img
        imageName={matchingImageNode.public_id}
        cloudName='frannsoft'
        backgroundColor={true}
        fluid={{ maxWidth: 1300, maxHeight: 600 }}
        alt={title}
        style={{
          borderRadius: `0.25rem 0.25rem 0 0`
        }}
      />
    );

    const faqSection = faqSections[index];
    const textContent = (
      <div className="sm:px-8">
        <OfferingTextContent key={index}
          header={faqSection.header}
          subtitle={faqSection.subtitle}
          detail={faqSection.detail}
        />
      </div>
    );

    const serviceCardComponent = (
      <ServiceCard
        imgComponent={imgComponent}
        url={url}
        title={title}
        description={description}
      />
    );

    return (
      <div className="md:px-4 lg:px-12 my-12" key={index}>
        <SectionContainer className='hidden sm:flex lg:h-screen-1/4 items-center'>
          {index % 2 ? (
            <>
              {serviceCardComponent}
              {textContent}
            </>
          ) : (
              <>
                {textContent}
                {serviceCardComponent}
              </>
            )}
        </SectionContainer>
        <SectionContainer className='flex sm:hidden'>
          {textContent}
          {serviceCardComponent}
        </SectionContainer>
      </div>
    );

  });
  return <ServiceLayout titleComponent={titleComponent} wipWiderLayout
    helmetTitle=" Website Designer, Web Developer Cleveland, Westlake, OH | Red Brick Road Studio"
  >
    {simpleWebsite}
    {content}
  </ServiceLayout>;
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { tags: { in: "websites" } } }) {
      nodes {
        frontmatter {
          title
          description
          public_id
          url
        }
      }
    }
    allCloudinaryMedia(filter: { url: { regex: "*/(websites)/" } }) {
      nodes {
        public_id
        url
      }
    }
  }
`;
